<template>
  <div>
    <b-button
      size="sm"
      variant="primary mr-1 ml-2"
      @click="handleShowModal"
    >
      <span class="ml-25 align-middle">Change position</span>
    </b-button>
    <!-- Modal change position sdp-account -->
    <b-modal
      ref="modal"
      title="Change list position"
      centered
      cancel-variant="outline-secondary"
      @ok="handleSubmitModal"
    >
      <b-row>
        <b-col md="12">
          <draggable
            :list="listItem"
            tag="ul"
            group="people"
            class=" cursor-move prevent-select "
            style="padding: 0 !important;"
          >
            <b-list-group-item
              v-for="(item, index) in listItem"
              :key="index"
              tag="li"
              class="list"
            >
              <div class="d-flex">
                <b-media vertical-align="center">
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold d-block text-nowrap text-body">
                      {{ `${index + 1}, ${ item.sportLeagueId}` }}
                    </div>
                  </div>
                  <!-- <small class="text-muted">CompId: {{ item.competitionId }}</small> -->
                </b-media>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>
      </b-row>
      <template #modal-ok>
        <span>Save</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BMedia, BButton, BListGroupItem, VBModal,
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import useToastHandler from '@/services/toastHandler'
import { avatarText } from '@core/utils/filter'

import draggable from 'vuedraggable'
import { arrayProp, stringProp } from '@/helpers/props'

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BButton,
    draggable,
    BListGroupItem,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    list: arrayProp(),
    type: stringProp(),
  },
  data() {
    return {
      listItem: [],
    }
  },
  computed: {
    ...mapState('races/listComp', ['loadingSortFeature']),
  },
  created() {
    this.listItem = this.list
  },
  methods: {
    ...mapActions({
      handleUpdateOrdernumberLeagues: 'races/sport/handleUpdateOrdernumberLeagues',
      handleUpdateOrdernumberLeaguesTipping: 'tipping/league/handleUpdateOrdernumberLeagues',
    }),
    async handleShowModal() {
      this.listItem = this.list
      this.$refs.modal.show()
    },
    async handleSubmitModal() {
      const data = {
        leagues: this.listItem.map((i, ind) => ({
          sportLeagueId: i.sportLeagueId,
          orderNumber: ind + 1,
        })),
      }
      if (this.type === 'sport') {
        const res = await this.handleUpdateOrdernumberLeagues(data)
        if (res) {
          this.showToastSuccess('Success', 'Position changed successfully!')
          this.$emit('change-position')
        }
      }
      if (this.type === 'tipping') {
        const res = await this.handleUpdateOrdernumberLeaguesTipping(data)
        if (res) {
          this.showToastSuccess('Success', 'Position changed successfully!')
          this.$emit('change-position')
        }
      }
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    return {
      avatarText,
      showToastError,
      showToastSuccess,
    }
  },
}
</script>
